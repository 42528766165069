.rc-select {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  position: relative;

  &-disabled {
    &,
    & input {
      cursor: not-allowed;
    }

    .rc-select-selector {
      opacity: 0.3;
    }
  }

  &-show-arrow, &-loading {
    .rc-select-arrow {
      &-icon::after {
        box-sizing: border-box;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        border: 2px solid #999;
        border-top-color: transparent;
        border-bottom-color: transparent;
        transform: none;
        margin-top: 4px;

        animation: rcSelectLoadingIcon 0.5s infinite;
      }
    }
  }

  // ============== Selector ===============
  .rc-select-selection-placeholder {
    opacity: 0.4;
    pointer-events: none;
  }

  // ============== Search ===============
  .rc-select-selection-search-input {
    appearance: none;

    &::-webkit-search-cancel-button {
      display: none;
      appearance: none;
    }
  }

  // --------------- Single ----------------
  &-single {
    .rc-select-selector {
      display: flex;
      position: relative;

      .rc-select-selection-search {
        width: 100%;

        &-input {
          width: 100%;
        }
      }

      .rc-select-selection-item,
      .rc-select-selection-placeholder {
        position: absolute;
        top: 1px;
        left: 3px;
        pointer-events: none;
      }
    }

    // Not customize
    &:not(.rc-select-customize-input) {
      .rc-select-selector {
        padding: 1px;
        border: 1px solid #000;

        
        .rc-select-selection-search-input {
          border: none;
          outline: none;
          background: rgba(255, 0, 0, 0.2);
          width: 100%;
        }
      }
    }
  }

  // -------------- Multiple ---------------
  &-multiple .rc-select-selector {
    display: flex;
    flex-wrap: wrap;
    padding: 1px;
    border: 1px solid #000;

    .rc-select-selection-item {
      flex: none;
      background: #bbb;
      border-radius: 4px;
      margin-right: 2px;
      padding: 0 8px;

      &-disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .rc-select-selection-overflow {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      &-item {
        flex: none;
        max-width: 100%;
      }
    }

    .rc-select-selection-search {
      position: relative;
      max-width: 100%;

      &-input,
      &-mirror {
        padding: 1px;
        font-family: system-ui;
      }

      &-mirror {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        position: none;
        left: 0;
        top: 0;
        visibility: hidden;
      }
    }

    
    .rc-select-selection-search-input {
      border: none;
      outline: none;
      background: rgba(255, 0, 0, 0.2);
      width: 100%;
    }
  }

  // ================ Icons ================
  &-allow-clear {
    &.rc-select-multiple .rc-select-selector {
      padding-right: 20px;
    }

    .rc-select-clear {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }

  &-show-arrow {
    &.rc-select-multiple .rc-select-selector {
      padding-right: 20px;
    }

    .rc-select-arrow {
      pointer-events: none;
      position: absolute;
      right: 5px;
      top: 0;

      &-icon::after {
        content: '';
        border: 5px solid transparent;
        width: 0;
        height: 0;
        display: inline-block;
        border-top-color: #999;
        transform: translateY(5px);
      }
    }
  }


  // ============== Dropdown ===============
  &-dropdown {
    border: 1px solid #3A3A3A;
    min-height: 100px;
    position: absolute;
    background: #fff;
    width: calc(100% - 32px) !important;
    max-width: 736px;
    left: 50% !important;
    transform: translateX(-50%);

    &-hidden {
      display: none;
    }
  }

  // =============== Option ================
  &-item {
    font-size: 16px;
    line-height: 1.5;
    padding: 4px 16px;

    // >>> Group
    &-group {
      color: #999;
      font-weight: bold;
      font-size: 80%;
    }

    // >>> Option
    &-option {
      position: relative;

      &-grouped {
        padding-left: 24px;
      }

      .rc-select-item-option-state {
        position: absolute;
        right: 0;
        top: 4px;
        pointer-events: none;
      }

      // ------- Active -------
      &-active {
        background: #ddd;
      }

      // ------ Disabled ------
      &-disabled {
        color: #999;
      }
    }

    // >>> Empty
    &-empty {
      text-align: center;
      color: #999;
    }
  }
}

.rc-select-selection__choice-zoom {
  transition: all 0.3s;
}

.rc-select-selection__choice-zoom-appear {
  opacity: 0;
  transform: scale(0.5);

  &-active {
    opacity: 1;
    transform: scale(1);
  }
}
.rc-select-selection__choice-zoom-leave {
  opacity: 1;
  transform: scale(1);

  &-active {
    opacity: 0;
    transform: scale(0.5);
  }
}

.rc-select-dropdown {
  &-slide-up-enter,
  &-slide-up-appear {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 0;
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-play-state: paused;
  }

  &-slide-up-leave {
    animation-duration: 0.3s;
    animation-fill-mode: both;
    transform-origin: 0 0;
    opacity: 1;
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-play-state: paused;
  }

  &-slide-up-enter, &-slide-up-enter-active, &-placement-bottomLeft,
  &-slide-up-appear, &-slide-up-appear-active, &-placement-bottomLeft,
  &-slide-up-enter, &-slide-up-enter-active, &-placement-bottomRight,
  &-slide-up-appear, &-slide-up-appear-active, &-placement-bottomRight {
    animation-name: rcSelectDropdownSlideUpIn;
    animation-play-state: running;
  }

  &-slide-up-leave, &-slide-up-leave-active, &-placement-bottomLeft,
  &-slide-up-leave, &-slide-up-leave-active, &-placement-bottomRight {
    animation-name: rcSelectDropdownSlideUpOut;
    animation-play-state: running;
  }

  &-slide-up-enter, &-slide-up-enter-active, &-placement-topLeft,
  &-slide-up-appear, &-slide-up-appear-active, &-placement-topLeft,
  &-slide-up-enter, &-slide-up-enter-active, &-placement-topRight,
  &-slide-up-appear, &-slide-up-appear-active, &-placement-topRight {
    animation-name: rcSelectDropdownSlideDownIn;
    animation-play-state: running;
  }

  &-slide-up-leave, &-slide-up-leave-active, &-placement-topLeft,
  &-slide-up-leave, &-slide-up-leave-active, &-placement-topRight {
    animation-name: rcSelectDropdownSlideDownOut;
    animation-play-state: running;
  }
}

@keyframes rcSelectDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes rcSelectDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcSelectDropdownSlideDownIn {
  0% {
    transform: scaleY(0);
    transform-origin: 100% 100%;
    opacity: 0;
  }

  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}

@keyframes rcSelectDropdownSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }

  100% {
    transform: scaleY(0);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}

@keyframes rcSelectLoadingIcon {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

// hour component
// .rc-time-picker-panel-narrow {
//   width: calc(100% - 32px);
//   max-width: 736px;
//   .rc-time-picker-panel-input-wrap input {
//     opacity: 0;
//   }
//   .rc-time-picker-panel-inner {
//     width: 100%;
//     background: transparent !important;
//     border-radius: 4px;
//     overflow: hidden;
//     // margin-left: 0px !important;
//   }
//   .rc-time-picker-panel-combobox {
//     margin-top: 20px;
//   }
//   .rc-time-picker-panel-select {
//     width: 50%;
//     min-height: 144px;
//     background-color: #3A3A3A;
//   }
// }
@import './rc-select.scss';
@import './spinner.scss';

$aqua: #B6FDFE;
$grey-90: #3A3A3A;
$grey-20: #D9D9D9;

$s-mobile: 480px;

@font-face {
  font-family: 'pirulen';
  src: url('../assets/fonts/pirulen-rg.otf') format('otf'),
       url('../assets/fonts/pirulen-rg.woff') format('woff'),
       url('../assets/fonts/pirulen-rg.ttf') format('ttf');
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'pirulen';
}

body {
  background-color: #0e141c;
}

.main {
  background-image: url('../assets/images/bg-booking.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
}

.btn {
  cursor: pointer;
  width: 100%;
  border-radius: 100px;
  font-size: 16px;
  padding: 10px 0px;
  text-align: center;
  color: #222;

  &.btn-aqua-glow {
    background-color: $aqua;
    box-shadow: 0 0 10px $aqua;
    border: 0px;
  }
}

.booking-page {
  width: 100%;
  position: relative;
  padding: 28px 16px 210px;
  // background: linear-gradient(187.72deg, #203A4F 19.52%, #000000 82.35%);
  min-height: 100vh;

  .header {
    position: relative;
    margin-bottom: 20px;
    .btn-back {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: transparent;
      border: 0px;
      padding: 8px;
      cursor: pointer;
      z-index: 1;
      .svg-icon {
        min-width: 8px !important;
        min-height: 16px !important;
      }
    }
  }

  .form {
    margin-bottom: 24px;
  }
  .booking-info {
    width: 100%;
    padding: 10px;
    border: 1px solid $aqua;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px $aqua;
    line-height: 1.5;
    background-color: #222;
    margin-bottom: 28px;
    font-size: 11px;
    color: white;

    @media (max-width: $s-mobile) {
      font-size: 8px;
    }
  }

  .table-number {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    span {
      min-width: 120px;
      display: inline-block;
      border-radius: 100px;
      font-size: 12px;
      padding: 8px 12px;
      text-align: center;
      color: #222;
      background-color: $aqua;
      box-shadow: 0 0 10px $aqua;
      border: 0px;
    }
  }

  .min-charge {
    line-height: 1.5;
    font-size: 11px;
    color: white;
    margin-bottom: 20px;
  }

  .booking-events-wrap {
    .title {
      font-size: 14px;
      color: white;
      margin-bottom: 8px;
    
      @media (max-width: $s-mobile) {
        font-size: 12px;
      }
    }
    .booking-events-box {
      $event-box-height: 40px;
      $event-box-margin: 20px;
      $event-box-count: 3;

      box-shadow: 0px 0px 14px 0px #00000040;
      background: rgba(#313131, .8);
      padding: 12px;
      margin-bottom: 24px;
      border-radius: 12px;
      max-height: calc(( $event-box-height + $event-box-margin ) * $event-box-count);

      overflow-y: scroll;
    }
    .booking-event {
      display: flex;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      .image {
        width: 70px;
        height: 40px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
      }
      .content-wrap {
        padding-left: 10px;
      }
      .text,
      .time {
        font-size: 14px;

        @media (max-width: $s-mobile) {
          font-size: 12px;
        }
      }
      .text {
        color: white;
      }
      .time {
        font-size: 12px;
        color: #A0A0A0;
      }
    }
  }

  .modal {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 5;
    padding: 0px 16px;
    .modal-background {
      background-color: rgba(0,0,0, 0.4);
      width: 100%;
      height: 100%;
    }
    .modal-box {
      text-align: center;
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      background-color: $grey-90;
      border-radius: 12px;
      padding: 16px;
      width: calc(100% - 32px);
      .svg-icon {
        background-color: $grey-20;
      }
      p {
        margin-top: 16px;
        color: $grey-20;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

  .booking-bottom-page {
    padding: 0px 16px 20px;
    position: absolute;
    bottom: 0; left: 0;
  }

  // Success Page
  &.success {
    padding-bottom: 68px;

    .transition {
      transform: translateY(100px);
      opacity: 0;
      transition: .4s;

      &.active {
        transform: translateY(0px);
        opacity: 1;
        transition: .4s;
      }
    }
  }
  .success-header-wrap {
    position: relative;
    text-align: center;
    .lottie {
      width: 100%;
      height: 124px;
      position: relative;
      z-index: 1;
    }
    .text {
      z-index: 1;
      color: $grey-20;
      position: relative;
      font-size: 14px;
      
      @media (max-width: $s-mobile) {
        font-size: 12px;
      }
    }
  }
  .success-info-wrap {
    position: relative;
    z-index: 1;
    padding: 16px;
    background-color: rgba($grey-90, .8);
    border-radius: 12px;
    margin-top: 16px;
    .info-wrap {
      margin-bottom: 20px;
      .text {
        color: $grey-20;
        font-size: 14px;
      
        @media (max-width: $s-mobile) {
          font-size: 12px;
        }
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .success-button-wrap {
    padding: 24px 0px;
    position: relative;
    bottom: 0; left: 0;
    width: 100%;
  }
}

.form-item {
  margin-bottom: 8px;
  .form-item-inner {
    background-color: $grey-90;
    border-radius: 4px;
    position: relative;
    padding: 16px 12px;
    &.hasSelect {
      padding-right: 32px;
    }
    .label {
      color: $grey-20;
      font-size: 12px;
      margin-bottom: 8px;

      @media (max-width: $s-mobile) {
        font-size: 9px;
      }
    }
    .form-input {
      color: $grey-20;
      font-size: 14px;
      
      @media (max-width: $s-mobile) {
        font-size: 12px;
      }
    }
    .arrow {
      display: inline-block;
      position: absolute;
      top: 50%; right: 20px;
      transform: translateY(-50%) rotate(270deg);
      .svg-icon {
        min-width: 8px !important;
        min-height: 16px !important;
      }
    }
  }
  .error-message {
    margin-top: 4px;
    font-size: 7px;
    color: #F05C2B;
    display: none;
  }
  &.error {
    .form-item-inner {
      border: 1px solid #F05C2B;
      box-shadow: 0px 0px 11px 0px #FF4B4B;
    }
    .error-message {
      display: block;
    }
  }
}

.lottie-div {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fireworks-lottie {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  
  &:after {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

// Custom CSS for Form Input, Datepicker, Timepicker
// RC Input - Start
.rc-input {
  width: 100%;
  background-color: transparent;
  border: 0px;
  color: #d9d9d9;
  outline: 0px;
  font-size: 12px;
  &:active, &:focus {
    border: 0px;
  }
}
.rc-input-group-wrapper .rc-input-wrapper {
  input {
    width: calc(100% - 50px);

    @media (max-width: $s-mobile) {
      width: calc(100% - 41px);
    }
  }
}
// RC Input - Start

// RC Select - Start
.rc-select {
  width: 100%;
  .rc-select-single:not(.rc-select-customize-input) .rc-select-selector {
    border: 0px;
    padding: 0px;
  }
  .rc-select-selector {
    border: 0px !important;
  }
  input {
    border:0px;
    outline: 0px;
  }
}
.rc-select-dropdown {
  .rc-virtual-list-holder-inner {
    background: $grey-90;
    padding: 0px 20px;
  }
  // .rc-select-item-option-content:after {
  //   content: 'PAX';
  //   display: inline-block;
  //   margin-left: 20px
  // }
  .rc-select-item.rc-select-item-option {
    background-color: $grey-90;
    color: rgba(#fff, .5);
  }
  .rc-select-item.rc-select-item-option.rc-select-item-option-selected,
  .rc-select-item.rc-select-item-option.rc-select-item-option-active.rc-select-item-option-selected {
    color: #fff;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    .rc-select-item-option-content:after {
      content: 'PAX';
      display: inline-block;
      margin-left: 20px
    }
  }
  .rc-select-item.rc-select-item-option.rc-select-item-option-active {
    color: #fff;
  }
  
  .rc-select-item-option-state-icon {
    opacity: 0;
  }
}
// RC Select - End

.rc-time-picker {
  width: 100%;
  .rc-time-picker-input {
    background-color: transparent;
    color: $grey-20;
    border: 0px;
    height: auto;
    font-size: 12px;
    padding: 0px;
    outline: 0px;
  }
  .rc-time-picker-clear {
    display: none;
  }
}
.rc-time-picker-panel.rc-book-time 
.rc-time-picker-panel {
}
.rc-time-picker-panel.rc-book-time {
  // width: 100% !important;
  .rc-time-picker-panel-input-wrap {
    border-bottom: 0px;
    padding-left: 12px;
  }
  .rc-time-picker-panel-input-wrap input {
    background-color: transparent;
    outline: 0px; border: 0px;
    font-size: 12px;
    color: $grey-20;
  }
  .rc-time-picker-panel-inner {
    background-color: $grey-90;
    border: 0px;
    box-shadow: 0px 0px 0px transparent;
    margin-left: -12px;
  }
  .rc-time-picker-panel-select ul li {
    &:hover {
      background-color: $grey-90;
      color: #fff;
    }
    &.rc-time-picker-panel-select-option-selected {
      background-color: $grey-90;
      color: #fff;
    }
    color: rgba(#fff, .5)
  }
}

.react-datepicker-component {
  .react-datepicker {
    margin-top: 25px !important;
    margin-left: -12px !important;
  }
  .react-datepicker-input {
    background: transparent !important;
    border: 0px !important;
    height: auto !important;
    position: relative;
    input {
      color: $grey-20 !important;
      background: transparent;
      padding: 0px !important;
      border: 0px !important;
      outline: 0px;
      font-size: 12px !important;
    }
    .button-wrapper {
      width: 100%; height: 100%;
      left: 0; top: 0;
      .input-button {
        margin: 0px;
        width: 100%; height: 100%;
      }
      .icon-rc-datepicker {
        opacity: 0;
      }
    }
  }
}
.react-datepicker {
  width: 100%;
  .react-datepicker-container {
    width: calc(100% + 44px) !important;
    .react-datepicker-top {
      background: $grey-90 !important;
      border-top-color: $grey-90 !important;
      border-left-color: $grey-90 !important;
      border-right-color: $grey-90 !important;
      &:before, &:after {
        display: none !important;
      }
    }
    .react-datepicker-body {
      background: $grey-90 !important;
      border-bottom-color: $grey-90 !important;
      border-left-color: $grey-90 !important;
      border-right-color: $grey-90 !important;
      &.react-datepicker-row .react-datepicker-picker.current {
        background-color: $grey-90 !important;
        &:hover {
          color: rgba(#fff, .5) !important;
        }
      }

    }
  }
}

// Change Styling Scroll bar - Start
body,
.booking-events-box {
  /* width */
  &::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
    box-shadow: inset 0 0 5px rgba(0,0,0, 0.3); 
    border-radius: 10px;

  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: transparent; 
    border-radius: 10px;
    transition: .4s;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: darken(grey, 10); 
    border-radius: 10px;
    transition: .4s;
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken(grey, 20); 
  }
}
// Change Styling Scroll bar - End